/**
 * Gets the orientation of the screen 
 * @returns {string} orientation
 */
export function getOrientation() {
	const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
	const orientation = (width > height ? 'landscape' : 'portrait');
	return orientation;
}

/**
 * Gets the aspect ratio of the screen
 * @returns {number} aspectRatio
 */
export function getAspectRatio() {
	const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
	const aspectRatio = parseFloat((width / height).toFixed(2));
	return aspectRatio;
}

/**
 * Checks if the app is running in standalone mode on mobile
 * @returns {bool} isInStandaloneMode
 */
export function checkIfInStandaloneMode()  {
	const isInStandaloneMode = 
		window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches;
	return isInStandaloneMode;
}